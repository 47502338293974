import React from "react";
import classes from "./experience-card.module.scss";
import SkillCard from "../skill-card/skill-card.component";

function ExperienceCard({
  isDisabled,
  isFeedbackEnabled,
  skills,
  inputChangeHandler,
  experienceForm,
}: any) {

  return (
    <div className={classes.ExperienceCard}>
      <div className={classes.SkillCard}>
        {skills?.map((currSkill: any, index: number) => (
          <div
            style={index !== 0 ? { marginTop: "1.5rem" } : {}}
            key={currSkill.id}
          >
            <SkillCard
              isDisabled={isDisabled}
              isFeedbackEnabled={isFeedbackEnabled}
              skill={currSkill}
              index={index + 1}
              inputChangeHandler={(value: string, key: string) => inputChangeHandler(index, value, key)}
              experienceForm={experienceForm[index]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExperienceCard;
