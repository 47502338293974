import classes from "./logout-form.module.scss";
import { useNavigate } from "react-router-dom";

const LogoutForm = () => {
    const navigate = useNavigate();

  const navigateToLogin = () => {
      navigate("/");
  };

  return (
    <div className={classes.LogoutForm}>
      <h1 className={classes.Title}>You have been successfully logged out.</h1>
      <div className={classes.Actions}>
        <button
          className={`${classes.LoginButton} hand`}
          onClick={navigateToLogin}
        >
          Login
        </button>
        <span>You can again login by clicking the button above.</span>
      </div>
    </div>
  );
};

export default LogoutForm;
