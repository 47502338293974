import classes from "./explore-aarohan.module.scss";
import SolidLink from "../../../components/solid-link/solid-link.component";

const options = [
  {
    id: 1,
    label: "About Aarohan",
    navigateTo: "https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali%5Fr%5Fudaan%5Fcom%2FDocuments%2FAarohan%2FAbout%20Aarohan&ga=1"
  },
  {
    id: 2,
    label: "Experience/Skill Maps",
    navigateTo: "https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali%5Fr%5Fudaan%5Fcom%2FDocuments%2FAarohan%2FExperience%20Maps&ga=1"
  },
  {
    id: 3,
    label: "Career Crusade Series",
    navigateTo: "https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali_r_udaan_com%2FDocuments%2FAarohan%2FCareer%20Crusade%20Series&ga=1"
  },
  {
    id: 4,
    label: "Mentoring Program",
    navigateTo: "/explore-mentoring",
    target : "_self"
  },
  {
    id: 5,
    label: "Learning @udaan",
    navigateTo: "https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali%5Fr%5Fudaan%5Fcom%2FDocuments%2FAarohan%2FLearning%20%40udaan&ga=1"
  }  ,
  {
    id: 6,
    label: "Internal Job Openings",
    navigateTo: "https://udaan.darwinbox.in/recruitment/recruitment/jobsijpemployee"
  }
];
const ExploreAarohan = () => {
  return (
    <div className={classes.Explore}>
      {options?.map(({ id, label, navigateTo, target }) => (
        <div key={id} className={classes.Link}>
          <SolidLink id={id} label={label} navigateTo={navigateTo} target={target}/>
        </div>
      ))}
    </div>
  );
};

export default ExploreAarohan;
