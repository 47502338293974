import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, FieldArray, Form, Formik } from 'formik';
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextareaAutosize,
    TextField,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormData, setCurrentFormData } from '../../../store/slices/career.slice';
import Checkbox from '@mui/material/Checkbox';
import classes from './development-plan-form.module.scss';
import { useNavigate } from 'react-router-dom';
import { createTransitionRequestService, updateStatusAndFeedbackService } from '../../../services/career-conversation';
import CustomDropdown from "../../custom-dropdown/custom-dropdown";
import { getDateYYYYMMDD, getEpoch } from "../../../utils/utils";
import CustomTooltip from "../../custom-tooltip/custom-tooltip.component";

type Props = {
    isDisabled: boolean,
    submitEnabled: boolean,
    isManagerFeedback: boolean,
    parentKey: string,
    handleNext?: any
}

const DevelopmentPlanForm = (props: Props) => {
    const navigate = useNavigate();
    const { parentKey, isDisabled, submitEnabled, isManagerFeedback } = props;
    const formData = useSelector((state: any) => state.career.currentFormData);
    const dispatch = useDispatch();
    const blankFormObj = {
        details: "",
        role: "",
        completedBy: "",
        stepsToAchieve70: "",
        stepsToAchieve20: "",
        stepsToAchieve10: "",
        status: ""
    }
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (!formData[parentKey].numberOfStrengths) {
            dispatch(setCurrentFormData({ formName: parentKey, formData: { numberOfStrengths: 1, strengths: [blankFormObj] } }));
        }
    }, []);

    const validationSchema = Yup.object().shape({
        numberOfStrengths: Yup.string()
            .required('Number of strengths is required'),
        strengths: Yup.array().of(
            Yup.object().shape({
                details: Yup.string()
                    .required('Details is required'),
                role: Yup.string()
                    .required('Current role / Next role is required'),
                completedBy: Yup.string()
                    .required('Complete by is required'),
                stepsToAchieve70: Yup.string()
                    .required('All the 3 learning principles are required'),
                stepsToAchieve20: Yup.string()
                    .required('All the 3 learning principles are required'),
                stepsToAchieve10: Yup.string()
                    .required('All the 3 learning principles are required'),
                status: Yup.string()
                    .required('Status role / Next role is required')
            })
        )
    });

    const onSubmit = async (fields: any) => {
        try {
            let tempFormData = JSON.parse(JSON.stringify(formData));
            tempFormData["section2"].questionAnswers.forEach((item: any) => {
                tempFormData["section1"].questionAnswers.push(item);
            });
            tempFormData[parentKey].strengths.map((item: any) =>
                item.completedBy = getEpoch(item.completedBy)
            )

            delete tempFormData["section2"].questionAnswers;
            if (isManagerFeedback) {
                await updateStatusAndFeedbackService(tempFormData, tempFormData["section2"].transitionData.requestId);
            } else {
                await createTransitionRequestService(tempFormData);
            }
            dispatch(resetFormData());
            if (isManagerFeedback) {
                alert("You have successfully reviewed your team member's action plan. Please ensure timely connects with the team member for continued success.")
            } else {
                alert("Your action plan has been created and has been shared with your manager for review");
            }
            navigate("/home");
        } catch (err) {
            alert("error");
        }
    }

    const handleOnBlur = (e: any, values: any) => {
        dispatch(setCurrentFormData({ formName: parentKey, formData: { ...values } }));
    }

    const handleManagerEmailChange = (e: any) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        if (!sectionData["section2"].transitionData) {
            sectionData["section2"].transitionData = {}
        }
        if (!sectionData["section2"].managerFeedback) {
            sectionData["section2"].managerFeedback = {}
        }
        sectionData["section2"].transitionData.managerEmail = e.target.value;
        sectionData["section2"].managerFeedback.managerEmail = e.target.value;
        dispatch(setCurrentFormData({ formName: "section2", formData: sectionData["section2"] }));
    }

    const handleFeedbackHandler = (e: any) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        if(!sectionData[parentKey].managerIjpFeedback) {
            sectionData[parentKey].managerIjpFeedback = {}
        }
        sectionData[parentKey].managerIjpFeedback.feedback = e.target.value;
        sectionData[parentKey].managerIjpFeedback.managerEmail = '';
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }

    const handleCheckboxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <>
            {formData[parentKey]?.strengths?.length && <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={formData[parentKey]}
                onSubmit={onSubmit}>
                {({ errors, values, touched, setValues, handleChange }) => (
                    <Form>
                        <FieldArray name="strengths">
                            {() => (values.strengths.map((strength: any, i: number) => {
                                return (
                                    <Box key={i} sx={{
                                        '& .MuiTextField-root': { m: 2 },
                                    }}
                                    >
                                        <Card style={{ boxShadow: 'none' }} className={classes.developmentCard}>
                                            <p className={classes.Label}>My Action Plan</p>
                                            <Field name="details">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{ py: 2, px: 3 }}>
                                                        <Typography component={'span'} className={classes.Description}>
                                                            1. What are the strengths I can leverage in my aspired next role?
                                                            <span className={classes.required}> *</span>
                                                            <CustomTooltip
                                                                title="Example: I am very good at working with
                                                                stakeholders across cross-functional teams & my aspired
                                                                role requires a lot of stakeholder engagement.
                                                                I also have an extremely high bias for action &
                                                                accountability."/>
                                                        </Typography>
                                                        <TextField
                                                            {...field}
                                                            style={{ margin: '0px 0' }}
                                                            disabled={isDisabled}
                                                            placeholder="Enter your areas of strength"
                                                            multiline
                                                            variant="outlined"
                                                            name={`strengths.${i}.details`}
                                                            minRows={3}
                                                            required
                                                            onBlur={(e) => handleOnBlur(e, values)}
                                                            value={strength.details || ''}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="role">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{ py: 2, px: 3 }}>
                                                        <Typography component={'span'} className={classes.Description}>
                                                            2. What are the strengths I need to build to get to my aspired next role?
                                                            <span className={classes.required}> *</span>
                                                            <CustomTooltip
                                                                title="Example: I need to get better at design thinking
                                                                & conceptualizing programs from beginning to end.
                                                                Execution comes naturally to me but in my aspired role,
                                                                I will be expected to ideate and bring thought
                                                                leadership to the table, as well. I also want to
                                                                understand the nuances of team management."/>
                                                        </Typography>
                                                        <TextField
                                                            {...field}
                                                            style={{ margin: '0px 0' }}
                                                            disabled={isDisabled}
                                                            placeholder="Enter the areas of strengths you need to build"
                                                            multiline
                                                            variant="outlined"
                                                            name={`strengths.${i}.role`}
                                                            minRows={3}
                                                            required
                                                            onBlur={(e) => handleOnBlur(e, values)}
                                                            value={strength.role || ''}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="stepsToAchieve70">
                                                {({ field, meta, error }: any) => (
                                                    <>
                                                        <FormControl fullWidth={true} sx={{ px: 3, py: 2 }}>
                                                        <Typography component={'span'} className={classes.Description}>
                                                            3. My action plan to get to my aspired next role: 70 - 20 - 10
                                                            <span className={classes.required}> *</span>
                                                            <CustomTooltip title={<>
                                                                Example of 70% :
                                                                <ul>
                                                                    <li>Build central understanding & program
                                                                        management muscle by taking up a central role
                                                                        in Operations or Strategy.</li>
                                                                    <li>Take up cross-functional projects that will
                                                                        involve ideation / design thinking</li>
                                                                    <li>Mentor a junior team member & set them up for
                                                                        success based on their career aspirations</li>
                                                                </ul>
                                                                Example of 20% :
                                                                <ul>
                                                                    <li>Get mentored by the head of the ops excellence
                                                                        team</li>
                                                                    <li>Seek 360 degree feedback from relevant senior
                                                                        stakeholders & work on the feedback received</li>
                                                                </ul>
                                                                Example of 10% :
                                                                <ul>
                                                                    <li>Get certified in design thinking:
                                                                        Practitioner & Expert certifications
                                                                        from IBM’s design thinking courses</li>
                                                                    <li>Attend the People Manager Capability
                                                                        training program</li>
                                                                </ul>
                                                            </>}>
                                                                </CustomTooltip>
                                                        </Typography>
                                                        </FormControl>
                                                        <FormControl fullWidth={true} sx={{ py: 2, px: 4 }}>
                                                            <span/>
                                                            <Typography component={'span'} className={classes.Description}>
                                                                i . 70% of the learning happens on the job.
                                                            </Typography>
                                                            <TextField
                                                                {...field}
                                                                style={{ margin: '0px 0' }}
                                                                disabled={isDisabled}
                                                                placeholder="Share your action plan"
                                                                multiline
                                                                variant="outlined"
                                                                name={`strengths.${i}.stepsToAchieve70`}
                                                                required
                                                                minRows={3}
                                                                onBlur={(e) => handleOnBlur(e, values)}
                                                                value={strength.stepsToAchieve70 || ''}
                                                            />
                                                        </FormControl>
                                                    </>
                                                )}
                                            </Field>
                                            <Field name="stepsToAchieve20">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{ py: 2, px: 4 }}>
                                                        <Typography component={'span'} className={classes.Description}>
                                                            ii . 20% of the learning happens through peer/social
                                                            learning and mentoring/coaching.
                                                        </Typography>
                                                        <TextField
                                                            {...field}
                                                            style={{ margin: '0px 0' }}
                                                            disabled={isDisabled}
                                                            placeholder="Share your action plan"
                                                            multiline
                                                            variant="outlined"
                                                            name={`strengths.${i}.stepsToAchieve20`}
                                                            required
                                                            minRows={3}
                                                            onBlur={(e) => handleOnBlur(e, values)}
                                                            value={strength.stepsToAchieve20 || ''}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="stepsToAchieve10">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{py:2, px: 4 }}>
                                                        <Typography component={'span'} className={classes.Description}>
                                                            iii . 10% of the learning happens through formal training.
                                                        </Typography>
                                                        <TextField
                                                            {...field}
                                                            style={{ margin: '0px 0' }}
                                                            disabled={isDisabled}
                                                            placeholder="Share your action plan"
                                                            multiline
                                                            variant="outlined"
                                                            name={`strengths.${i}.stepsToAchieve10`}
                                                            required
                                                            minRows={3}
                                                            onBlur={(e) => handleOnBlur(e, values)}
                                                            value={strength.stepsToAchieve10 || ''}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="completedBy">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{ py: 2, px: 3 }}>
                                                        <Typography component={'span'} className={classes.Description}>5. Target completion date <span className={classes.required}>*</span></Typography>
                                                        <TextField
                                                            {...field}
                                                            style={{ margin: '0px 0' }}
                                                            disabled={isDisabled}
                                                            required
                                                            type="date"
                                                            name={`strengths.${i}.completedBy`}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onBlur={(e) => handleOnBlur(e, values)}
                                                            value={getDateYYYYMMDD(strength.completedBy) || ''}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name="status">
                                                {({ field, meta, error }: any) => (
                                                    <FormControl fullWidth={true} sx={{ py: 2, px: 3 }}>
                                                        <Typography component={'span'} className={classes.Description}>6. Status <span className={classes.required}>*</span></Typography>
                                                        <CustomDropdown
                                                            {...field}
                                                            name={`strengths.${i}.status`}
                                                            options={[
                                                                { id: 1, value: "Not started" },
                                                                { id: 2, value: "In progress" },
                                                                { id: 3, value: "Completed" },
                                                            ]}
                                                            valueKey="value"
                                                            displayKey="value"
                                                            value={strength.status || ''}
                                                            disabled={isDisabled}
                                                            required={true}
                                                            placeholder={"Select Skill"}
                                                            handleBlur={(e: any) => handleOnBlur(e, values)}
                                                            changeHandler={handleChange}
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Card>
                                    </Box>
                                );
                            }))}
                        </FieldArray>
                           {isDisabled && <Field name="managerIjpFeedback">
                                {({ field, meta, error }: any) => (
                                    <FormControl sx={{ mb: 4 }} fullWidth={true}>
                                        <Typography component={'span'} className={classes.Description}> {'Manager comments'}  <span className={classes.required}>*</span></Typography>
                                        <TextareaAutosize
                                            disabled={!isManagerFeedback}
                                            placeholder={isManagerFeedback ? 'Share your comments on the focus areas' : 'Awaiting manager comments'}
                                            value={formData[parentKey]?.managerIjpFeedback?.feedback}
                                            minRows={5} className={classes.textarea}
                                            onChange={handleFeedbackHandler}
                                            required />
                                    </FormControl>
                                )}
                            </Field>}
                        {!isManagerFeedback && <Field name="managerEmail">
                            {({ field, meta, error }: any) => (
                                <FormControl sx={{ mb: 2 }} fullWidth={true}>
                                    <Typography component={'span'} className={classes.Description}>Manager's Email ID<span className={classes.required}> *</span></Typography>
                                    <TextField
                                        {...field}
                                        placeholder="Enter your manager's email id"
                                        variant="outlined"
                                        minRows={3}
                                        required
                                        type="email"
                                        onBlur={handleManagerEmailChange}
                                        value={values.managerEmail || ''}
                                        disabled={isDisabled}
                                    />
                                </FormControl>
                            )}
                        </Field>}
                        {isManagerFeedback && <Field name="checkStatus">
                            {({ field, meta, error }: any) => (
                                <FormControl fullWidth={true} sx={{ py: 0, px: 0 }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            style={{ width: 'max-content' }}
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={checked}
                                                    onChange={handleCheckboxCheck} />}
                                            label="I have completed the career conversation with the team member" />
                                    </FormGroup>
                                </FormControl>
                            )}
                        </Field>}
                        {submitEnabled &&
                            <Typography align='center'>
                                <Button type="submit" variant="contained" disabled={isManagerFeedback && !checked}>
                                    {submitEnabled && isManagerFeedback ? 'Reviewed' : 'Submit'}
                                </Button>
                            </Typography>
                        }
                    </Form>
                )}
            </Formik>}
        </>
    )
}

export default DevelopmentPlanForm;
