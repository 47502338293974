import BannerImage from "../../components/banner-image/banner-image.component";
import loginBackground from "../../assets/icons/login-background.svg";
import classes from "./login.module.scss";
import Navbar from "../../components/navbar/navbar.component";

const Login = ({ children }: any) => {
  return (
    <div className={classes.Login}>
      <BannerImage imageUrl={loginBackground}/>
      <Navbar/>
      <div className={classes.LoginForm}>
        {children}
      </div>
    </div>
  );
};

export default Login;
