import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import classes from "./custom-dropdown.module.scss";

function CustomDropdown({
    style,
  label,
  options,
  value,
  initialValue,
  changeHandler,
  valueKey,
  displayKey,
  disabled,
  styles,
  required,
  error,
  handleBlur,
  name
}: any) {

  const handler = (e: SelectChangeEvent) => {
    changeHandler(e);
  };

  return (
    <div className={classes.CustomDropdown}>
      <FormControl fullWidth error={error}>
        {label && <Typography component={'span'} className={classes.Description}>{label} <span className={classes.required}>*</span></Typography>}
        <Select
            style={style}
            name={name}
            placeholder={label}
            defaultValue={initialValue}
            disabled={!!disabled}
            value={value || ""}
            displayEmpty
            renderValue={value ? undefined : () => "Select"}
            onChange={handler}
            input={<OutlinedInput required />}
            onBlur={handleBlur}
        >
          {options.map((currOption: any) => (
            <MenuItem
              key={currOption.id}
              value={currOption[valueKey]}
            >
              {currOption[displayKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomDropdown;
