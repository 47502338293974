import classes from "./solid-link.module.scss";

const SolidLink = ({ navigateTo, label, target }: any) => {
  return (
    <a href={navigateTo} target={target ? target : "_blank"}  rel="noreferrer" className={classes.SolidLink}>
      <span>{label}</span>
    </a>
  );
};

export default SolidLink;
