import { Outlet, Router, useLocation } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import Navbar from "../../components/navbar/navbar.component";

const Dashboard = () => {
    return (
        <>
            <header>
                <Navbar loggedIn={true}/>
            </header>
            <main className="main">
                <Outlet/>
            </main>
            <footer className="footer">
                <Footer/>
            </footer>
        </>
    );
};

export default Dashboard;