import { Fragment } from "react";
import CustomDropdown from "../components/custom-dropdown/custom-dropdown";

export const generateFormControls = (
  controls: any[],
  cb: any,
  values: any
): any => {
  const formIds: any = {};
  return controls.map((control, index) => {
    switch (control.type) {
      case "SINGLE_SELECT": {
        formIds[control.id] = control.initialValue;
        return (
          <Fragment key={control.id}>
            {control.options?.length ? <CustomDropdown
              key={control.id}
              options={control.options}
              label={control.label}
              intialValue={control.initialValue}
              changeHandler={(event: any) => {
                cb(control.id, event, index);
              }}
              styles={index !== 0 ? { marginTop: "1rem" } : {}}
              disabled={control.disabled}
              value={values[control.id]}
              valueKey={control.valueKey}
              displayKey={control.displayKey}
              required={control.required}
            /> : null}
          </Fragment>
        );
      }
      default:
        break;
    }
  });
};
