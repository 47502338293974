import { combineReducers, configureStore } from '@reduxjs/toolkit';
import careerSlice from './slices/career.slice';

import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session'

const rootReducer: any = combineReducers({
    career: careerSlice
});

const persistConfig = {
    key: 'root',
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});
const persistor = persistStore(store);

export default store;
export { persistor };
