import classes from "./user-card.module.scss";

import logoutIcon from "../../assets/icons/logout.svg";

const UserCard = ({
  imageSrc,
  name,
  emailId,
  clickHandler,
  backdropClickHandler
}: any) => {
  return (
    <>
      <section className="overlay" onClick={backdropClickHandler}/>
      <section className={classes.UserCard}>
        <img src={imageSrc} className={classes.IconArea} alt={"Backdrop icon"}/>
        <h3 className={classes.NameArea}>{name}</h3>
        <p className={classes.EmailArea}>{emailId}</p>
        <div className={`${classes.ActionArea} hand`} onClick={clickHandler}>
          <img src={logoutIcon} className={classes.LogoutIcon} alt={"Logout icon"} />
          <span className={classes.LogoutArea}>Logout</span>
        </div>
      </section>
    </>
  );
};

export default UserCard;
