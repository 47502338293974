import React from "react";
import classes from "./form-header.module.scss";
import HorizontalStepper from "../stepper/stepper";

function FormHeader({ icon, label, subheading, steps, activeStep, completed, handleStep  }: any) {
  return (
    <section className={classes.FormHeader}>
      <img src={icon} className="main-logo"/>
      <p className={classes.TitleLabel}>{label}</p>
      <p className={classes.SubHeading}>{subheading}</p>
        <HorizontalStepper
            steps={steps}
            activeStep={activeStep}
            completed={completed}
            handleStep={handleStep} />
    </section>
  );
}

export default FormHeader;
