import React, {useState, Fragment, useEffect} from "react";
import classes from "./mentoring-diary-view.module.scss";
import FormHeader from "../../../components/form-header/form-header.component";
import udaanLogo from "../../../assets/icons/udaan-logo.svg"
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Card, FormControl, TextField, Typography} from "@mui/material";
import * as Yup from "yup";
import {getDateYYYYMMDD, getEpoch, getUserProfile} from "../../../utils/utils";
import {Field, Form, Formik} from "formik";
import {createNewMentoringDiary, getNoteByDiaryId, updateNotesInDiary} from "../../../services/diary.service";

type Props = {
    previousFormData?: any
}

const MentoringDiaryForm = (props: Props) => {
    const userProfile: any = getUserProfile();
    const navigate = useNavigate();
    const blankFormObj = {
        topic: "",
        dateOfConnect: "",
        notes: "",
        userEmail:""/*,
        mentorEmail: ""*/
    }

    const [formData, setFormData] = useState({...blankFormObj})

    const urlParams = useParams();
    const mentoringDiaryId = urlParams.id;

    useEffect( ()=> {
        if (mentoringDiaryId) {
             getNoteByDiaryId(mentoringDiaryId).then(({data}) => setFormData({...data[0],dateOfConnect: getDateYYYYMMDD(data[0].dateOfConnect)}))
        }
        },[])

    const handleOnBlur = (e: any, values: any) => {
        setFormData({...values})
    }


    const validationSchema = Yup.object().shape({
        topic: Yup.string()
                    .required('Details is required'),
        dateOfConnect: Yup.string()
                    .required('Current role / Next role is required'),
        notes: Yup.string()
                    .required('Complete by is required')
    });

    const onSubmit = async (fields: any) => {
        try {
            fields.userEmail = userProfile.emailId
            fields.dateOfConnect = getEpoch(fields.dateOfConnect)
            if(!!mentoringDiaryId) {
                delete fields.id
                delete fields.updatedAt
                await updateNotesInDiary(fields)
            } else {
                await createNewMentoringDiary(fields);

            }
            navigate("/explore-mentoring");
        } catch (err) {
            alert("error");
        }
    }


    return (
        <>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={formData}
                onSubmit={onSubmit}>
                {({ errors, values, touched, setValues, handleChange }) => (
                    <Form>
                        <Box sx={{
                            '& .MuiTextField-root': { m: 2 },
                        }}
                        >
                            <Card style={{ boxShadow: 'none' }} className={classes.developmentCard}>
                                <p className={classes.Label}>My Mentoring Diary</p>
                                <Field name="topic">
                                    {({ field, meta, error }: any) => (
                                        <FormControl fullWidth={true} sx={{ py: 1, px: 2 }}>
                                            <Typography component={'span'} className={classes.Description}>
                                                1. The topic that you are being mentored upon:
                                                <span className={classes.required}> *</span></Typography>
                                            <TextField
                                                {...field}
                                                style={{ margin: '12px 22px' }}
                                                placeholder="Mention the topic of discussion or the subject that you are being mentored upon"
                                                multiline
                                                variant="outlined"
                                                name="topic"
                                                required
                                                minRows={1}
                                                onBlur={(e) => handleOnBlur(e, values)}
                                                value={values.topic}
                                                disabled = {!!mentoringDiaryId}
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="dateOfConnect">
                                    {({ field, meta, error }: any) => (
                                        <FormControl fullWidth={true} sx={{ py: 1, px: 2 }}>
                                            <Typography component={'span'} className={classes.Description}>
                                                2 . The date of your mentor connect: <span className={classes.required}>*</span></Typography>
                                            <TextField
                                                {...field}
                                                style={{ margin: '12px 22px' }}
                                                required
                                                type="date"
                                                name="dateOfConnect"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onBlur={(e) => handleOnBlur(e, values)}
                                                value={values.dateOfConnect}
                                                disabled = {!!mentoringDiaryId}
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="notes">
                                    {({ field, meta, error }: any) => (
                                        <FormControl fullWidth={true} sx={{ py: 1, px: 2 }}>
                                            <span/>
                                            <Typography component={'span'} className={classes.Description}>
                                                3 . Notes
                                                <span className={classes.required}> *</span>
                                            </Typography>
                                            <TextField
                                                {...field}
                                                style={{ margin: '12px 22px' }}
                                                placeholder="You can use this as a diary to jot down any key points, focus areas, or just your thoughts."
                                                multiline
                                                variant="outlined"
                                                name="notes"
                                                required
                                                minRows={20}
                                                onBlur={(e) => handleOnBlur(e, values)}
                                                value={values.notes}
                                            />
                                        </FormControl>
                                    )}
                                </Field>
{/*                                <Field name="mentorEmail">
                                    {({ field, meta, error }: any) => (
                                        <FormControl fullWidth={true} sx={{ py: 1, px: 2 }}>
                                            <span/>
                                            <Typography component={'span'} className={classes.Description}>
                                                4 . You can also share your notes with your mentor. To do so, enter their email ID :
                                            </Typography>
                                            <TextField
                                                {...field}
                                                style={{ margin: '12px 22px' }}
                                                placeholder="Mentor's email ID"
                                                multiline
                                                variant="outlined"
                                                name="mentorEmail"
                                                minRows={1}
                                                onBlur={(e) => handleOnBlur(e, values)}
                                                value={values.mentorEmail}
                                                disabled = {!!mentoringDiaryId}
                                            />
                                        </FormControl>
                                    )}
                                </Field>*/}
                            </Card>
                        </Box>
                        <Typography align='center'>
                            {!!mentoringDiaryId ?
                                <Button type="submit" variant="contained" >
                                    Update
                                </Button> :
                                <Button type="submit" variant="contained" >
                                    Submit
                                </Button>}
                        </Typography>
                    </Form>
                )}
            </Formik>
        </>
    )
}


const MentoringDiaryView = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>({});

    const urlParams = useParams();

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const formStepsAndComponents = [
        {
            label: "My Mentoring Diary",
            subheading: "Use this space to capture the conversations that you have with your mentor(s).\n" +
                "You can use this as a diary to jot down any key points, focus areas, or just your thoughts.",
            component: <MentoringDiaryForm/>
        }
    ]

    return (
        <section className={classes.AppForm}>
            <FormHeader icon={udaanLogo}
                label={formStepsAndComponents[activeStep].label}
                subheading={formStepsAndComponents[activeStep].subheading}
                steps={[]}
                activeStep={activeStep}
                completed={completed}
                handleStep={handleStep}
            />
            <Box sx={{ width: '100%' }}>
                <div>
                    <Fragment>
                        <Box margin={4}>
                            {formStepsAndComponents[activeStep].component}
                        </Box>
                    </Fragment>
                </div>
            </Box>
        </section>
    );
}

export default MentoringDiaryView
