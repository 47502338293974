import ax, { AxiosResponse } from "axios";

import { getBearerToken } from "../utils/utils";

const baseApiUrl = process.env.NODE_ENV === "production" ? "/aarohan-console/api" : process.env.REACT_APP_API_HOST;

const timeout = 60 * 1000;

declare module 'axios' {
    export interface AxiosResponse<T = any> extends Promise<T> { }
}

export const axios = ax.create({
    baseURL: `${baseApiUrl}`,
    headers: {
        common: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + getBearerToken()
        },
    },
    timeout,
    // withCredentials: true,
});

axios.interceptors.request.use(
    (request) => {
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response): AxiosResponse => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);
