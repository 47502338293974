import { useCallback } from "react";
import { generateFormControls } from "../../helper/utils";

function ApplicationFormInputs({ values, inputChangeHandler, controls }: any) {
  const renderControls = useCallback(
    (controls: any[]): any => {
      return generateFormControls(controls, inputChangeHandler, values);
    },
    [controls, values]
  );

  return renderControls(controls) || null;
}

export default ApplicationFormInputs;
