import { axios } from "../config/axios";
import { getQuestionsBySectionId } from '../config/endpoint';

export type Question = {
    id: number;
    question: string,
    description: string
}

export const getQuestionsBySectionIdService = async (sectionId: number) => {
    return axios.get(`${getQuestionsBySectionId}?sectionId=${sectionId}`);
}