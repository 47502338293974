import classes from "./experience-viewer.module.scss";
import ExperienceCard from "../experience-card/experience-card.component";

function ExperienceViewer({
  isDisabled,
  isFeedbackEnabled,
  skills,
  inputChangeHandler,
  experienceForm,
}: any) {
  return (
    <div>
      <p className={classes.label}>Experiences / Skills Required:</p>
      <ExperienceCard
        isDisabled={isDisabled}
        isFeedbackEnabled={isFeedbackEnabled}
        skills={skills}
        inputChangeHandler={inputChangeHandler}
        experienceForm={experienceForm}
      />
    </div>
  );
}

export default ExperienceViewer;
