import classes from "./explore-category.module.scss";

const ExploreCategory = ({ icon, name }: any) => {
  return (
    <div className={`${classes.ExploreCategory} hand`}>
      <img src={icon} className={`${classes.ExploreCategoryImg}`} alt={"Explore icon"}/>
      <span className={classes.Label}>{name}</span>
    </div>
  );
};

export default ExploreCategory;
