import {REGEX} from "../constants/constants";
import {setCurrentFormData} from "../store/slices/career.slice";

export function getBearerToken() {
    const cookieData = document.cookie.match(REGEX.LOGIN_ID);
    return cookieData ? cookieData.pop() : "";
}

export function getUserProfile() {
    const cookieData = document.cookie.match(REGEX.USER_PROFILE);
    const finalCookieData: any = cookieData ? cookieData.pop() : "{}"
    return JSON.parse(finalCookieData);
}

export function deleteCookies() {
    const aaCookies = ['_login_id', '_user_profile'];
    for (const cookieName of aaCookies) {
        deleteCookie(cookieName);
    }
}

export function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getDateYYYYMMDD(datetime: number | string) {
    if (typeof datetime === "string") {
        return datetime
    }
    let dateObj = new Date(datetime);
    const yyyy = dateObj.getFullYear();
    let mm: number | string = dateObj.getMonth() + 1;
    let dd: number | string = dateObj.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

export function getEpoch(date: any) {
    const d = date.split('-');
    return (new Date(d[0], d[1] - 1, d[2])).valueOf()
}

export const getPlaceholder = (id: number) => {
    switch (id) {
        case 1 :
            return "Share the career experiences that you have had so far"
        case 2 :
            return "Share your key career highlights / milestones"
        case 3 :
            return "Reflect on the impact that you wish to leave in your role"
        case 4:
            return "Please share any mobility restrictions that you may have"
        default :
            return "Enter your response"
    }
}
