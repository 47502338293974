import React, { useEffect } from 'react';

import { GoogleAuthClientId } from '../../constants/constants';

type Props = {
    handleSignIn: (value: boolean) => void
}

declare global {
    interface Window {
        onGoogleScriptLoad?: any;
    }
}

const GoogleAuth = (props: Props) => {
    const { handleSignIn } = props;

    const onSuccess = (googleUser: any) => {
        const id_token = googleUser.getAuthResponse().id_token;
        const profile = googleUser.getBasicProfile()
        const hostedDomain = googleUser.getHostedDomain()
        if(checkUdaanUser(hostedDomain)) {
            saveProfileToken(profile)
            saveIdToken(id_token)
            handleSignIn(true)
            setupTokenReload(googleUser)
        } else {
            handleSignIn(false)
            alert("The application is restricted to udaan.com")
        }
    };

    const checkUdaanUser = (hostedDomain: any) => {
        if(hostedDomain === 'udaan.com' || hostedDomain === 'hiveloopcapital.com') {
            return true
        }
    }

    const onFailure = () => {
        handleSignIn(false)
        alert("Login failed");
    }

    const setupTokenReload = (googleUser: any) => {
        /* Reload Token for every 20 minutes */
        const reloadTimeMillis = 20 * 60 * 1000;
        setTimeout(function () { reloadToken(googleUser, true) }, reloadTimeMillis);
    }

    const reloadToken = (googleUser: any, to_poll: boolean) => {
        googleUser.reloadAuthResponse()
            .then(function (r: any) {
                saveIdToken(r.id_token);
                if (to_poll) {
                    setupTokenReload(googleUser);
                }
            });
    }

    const saveIdToken = (id_token: string) => {
        let secure = false;
        try {
            secure = window.location.protocol === 'https:';
        } catch (err) {
            console.log(`Error checking for secure ${err}`);
        }
        document.cookie = "_login_id=" + id_token + ";path=/" + (secure ? ';secure' : '');
    }

    const saveProfileToken = (profile: gapi.auth2.BasicProfile) => {
        let secure = false;
        try {
            secure = window.location.protocol === 'https:';
        } catch (err) {
            console.log(`Error checking for secure ${err}`);
        }
        const userObj = {
            "id" : profile.getId(),
            "emailId" : profile.getEmail(),
            "name" : profile.getName(),
            "givenName" : profile.getGivenName(),
            "imageUrl" : profile.getImageUrl()
        }
        document.cookie = "_user_profile=" + JSON.stringify(userObj) + ";path=/" + (secure ? ';secure' : '');
    }

    const renderSigninButton = (_gapi: typeof globalThis.gapi | undefined) => {
        _gapi?.signin2.render('google-signin', {
            'scope': 'profile email',
            'width': 240,
            'height': 50,
            'longtitle': true,
            'theme': 'dark',
            'onsuccess': onSuccess,
            'onfailure': onFailure
        });
    }

    const loadGoogleScript = () => {
        const id = 'google-js';
        const src = 'https://apis.google.com/js/platform.js';
        const firstJs = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) { return; }
        const js = document.createElement('script');
        js.id = id;
        js.src = src;
        js.onload = window.onGoogleScriptLoad;
        firstJs?.parentNode?.insertBefore(js, firstJs);
    }

    useEffect(() => {
        window.onGoogleScriptLoad = () => {
            const _gapi = window.gapi;
            _gapi.load('auth2', () => {
                (async () => {
                    _gapi.auth2.init({
                        client_id: GoogleAuthClientId
                    });
                    renderSigninButton(_gapi);
                })();
            });
        }
        loadGoogleScript();
    }, []);

    return (
        <div className="login-btn">
            <div id="google-signin"/>
        </div>
    )
}

export default React.memo(GoogleAuth);