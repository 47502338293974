import classes from "./login-form.module.scss";
import udaanLogo from "../../assets/icons/udaan-logo.svg";
import GoogleAuth from "../google-auth/google-auth";

type Props = {
  handleSignIn: (value: boolean) => void
}

const LoginForm = (props: Props) => {
  return (
    <div className={classes.LoginForm}>
      <section className={classes.FormHeader}>
        <img src={udaanLogo} className="main-logo" alt={"Udaan logo"}/>
        <h2 className={classes.TitleLabel}>Sign In</h2>
        <span className={`${classes.Content} hand`}>
          <GoogleAuth handleSignIn={props.handleSignIn} />
        </span>
      </section>
      <section className={classes.FormFooter}>
        <p className={classes.FormFooterLabel}>
          {new Date().getFullYear()} Hiveloop Solutions Pvt Ltd.
        </p>
        <span>Cookie Policy</span>
        <span>Terms of Use</span>
        <span>Privacy Policy</span>
      </section>
    </div>
  );
};

export default LoginForm;
