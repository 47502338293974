import { Route, Routes } from "react-router";
import Dashboard from "../../pages/dashboard/dashboard.component";
import Login from "../../pages/login/login.component";
import LoginForm from "../../components/login-form/login-form.component";
import LogoutForm from "../../components/logout-form/logout-form.component";
import Requests from "../../pages/dashboard/requests/requests.component";
import { useState } from "react";
import { getBearerToken } from "../../utils/utils";
import CareerConversation from "../../pages/career-conversation/career-conversation";
import MentoringDiaryView from "../../pages/mentee-view/mentoring-diary-form/mentoring-diary-view";
import Explore from "../../pages/dashboard/explore/explore.component";
import ExploreAarohan from "../../pages/dashboard/explore-aarohan/explore-aarohan.component";
import { Navigate } from 'react-router-dom';
import MentoringExplore from "../../pages/mentoring-dashboard/mentoring-explore/mentoring-explore.component";
import Diaries from "../../pages/mentee-view/diaries/diaries.component";

const AppRouter = (props: any) => {

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!getBearerToken());

  const handleSignIn = (value: boolean) => {
    setIsLoggedIn(value)
  }

  return (
    <Routes>
      <Route
          path="/logout"
          element={
            <Login>
              <LogoutForm/>
            </Login>
          }
      />
      {!isLoggedIn ?
          <>
        <Route path="/" element={<Login>
          <LoginForm handleSignIn={handleSignIn} />
        </Login>}
        />

            </>:
        <>
          <Route path="/" element={<Dashboard />}>
            <Route path="/requests/:userType" element={<Requests />}/>
            <Route path="/explore-aarohan" element={<ExploreAarohan />}/>
            <Route path="/career-conversation" element={<CareerConversation />}/>
            <Route path="/career-conversation/:userType/:action" element={<CareerConversation />}/>
              <Route path="/home" element={<Explore />}/>
              <Route path="/explore-mentoring" element={<MentoringExplore />}/>
              <Route path="/mentoring-diary" element={<MentoringDiaryView />}/>
              <Route path="/mentoring-diary/:id" element={<MentoringDiaryView />}/>
              <Route path="/list-diaries" element={<Diaries />}/>
              <Route path="/" element={<Navigate to="/home" />}/>
          </Route>
        </>
      }
    </Routes>
  );
};

export default AppRouter;
