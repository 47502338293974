import classes from "./mentoring-explore.module.scss";
import ExploreCategory from "../../../components/explore-category/explore-category.component";
import checkStatus from "../../../assets/icons/check-status-icon.png"
import { Link } from "react-router-dom";
import managerInput from "../../../assets/icons/manager-input-icon.svg";
import careerConversation from "../../../assets/icons/career-conversation-icon.svg";

const options = [
  {
    id: 1,
    name: "My Mentoring Diary",
    icon: managerInput,
    navigateTo: "/list-diaries"
  },
  {
    id: 2,
    name: "Create New Entry",
    icon: careerConversation,
    navigateTo: "/mentoring-diary"
  },
  {
    id: 3,
    name: "Resources",
    icon: checkStatus,
    navigateTo: "https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali%5Fr%5Fudaan%5Fcom%2FDocuments%2FAarohan%2FMentoring&ga=1",
    isExternal: true
  },
];
const MentoringExplore = () => {
  return (
    <div className={classes.Explore}>
      {options?.map(({ id, name, icon, navigateTo,isExternal }) => (
          <>
            {
              isExternal ?
              <a href={navigateTo} target={"_blank"}  rel="noreferrer">
                    <ExploreCategory id={id} name={name} icon={icon}/>
              </a> :
              <Link key={id} to={navigateTo}>
                <ExploreCategory id={id} name={name} icon={icon}/>
              </Link>
            }
          </>
      ))}
    </div>
  );
};

export default MentoringExplore;
