import { axios } from "../config/axios";
import { findRequestsByEmail, getCareerConversationByRequestId } from '../config/endpoint';
import { getDateYYYYMMDD } from "../utils/utils";

export type SubmittedFormData = {
    id: number
    requestId: string
    userName: string,
    userEmail: string,
    managerEmail: string,
    businessUnitId: number,
    categoryId: number,
    functionId: number,
    roleId: number,
    status: string,
    active: boolean,
    createdAt: number,
    updatedAt: number
}

export const findRequestsByEmailService = async (email: string, type: string) => {
    return axios.get(`${findRequestsByEmail}?requester=${type}&email=${email}`);
}

export const getCareerConversationByRequestIdService = async (requestId: string) => {
    let conversationData = await axios.get(`${getCareerConversationByRequestId}?reqId=${requestId}`);
    conversationData.data.formData["section2"].questionAnswers = conversationData.data.formData["section1"].questionAnswers.splice(3);
    conversationData.data.formData["section1"].questionAnswers.splice(3);
    conversationData.data.formData["section3"].numberOfStrengths = conversationData.data.formData["section3"].strengths.length;
    conversationData.data.formData["section3"].managerEmail = conversationData.data.formData["section2"].transitionData.managerEmail;
    conversationData.data.formData["section3"].strengths = conversationData.data.formData["section3"].strengths.map((item: any) => {
        return {
            ...item,
            completedBy: getDateYYYYMMDD(item.completedBy)
        }
    });
    return conversationData.data.formData;

}