import { Button } from "@mui/material";
import classes from "./custom-table.module.scss";

const CustomTable = ({ title, columns, data, actionHandler }: any) => {
  return (
    <div>
      {title && <p>{title}</p>}
      <table>
        <thead className={classes.TableHead}>
          <tr>
            {columns?.map((column: any) => (
              <th key={column.id} style={column?.style || {}}>
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((data: any, row: number) => {
            return (
              <tr key={data.id}
                className={data?.styleClasses?.join(" ") || ""}
              >
                {columns?.map((column: any) => (
                  <td
                    key={column.id}
                    style={{
                      ...(column?.style || {}),
                      ...(column?.dataStyle || {})
                    }}
                    className={column?.styleClasses?.join(" ") || ""}
                    data-row={row}
                    data-id={data.id}
                    data-column={column.id}
                  >
                    {column.id === 'requestId'?
                      <Button
                        data-row={row}
                        data-id={data.id}
                        data-column={column.id}
                        onClick={(e) => actionHandler(e, data.requestId)}
                      >
                        View
                      </Button> :
                        column.id === 'mentoringDiaryId'?
                            <Button
                                data-row={row}
                                data-id={data.id}
                                data-column={column.id}
                                onClick={(e) => actionHandler(e, data.mentoringDiaryId)}
                            >
                              View
                            </Button> : <span>{data[column.id]}</span>}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
