export const getQuestionsBySectionId = 'getQuestionsBySectionId';
export const getAllBusinessUnit = 'getAllBusinessUnit';
export const getCategoriesByBusinessUnitId = 'getCategoryByBusinessId';
export const getFunctionsByCatId = 'getFunctionsByCategoryId';
export const getCatFuncId = 'getByCatFuncId'
export const getRoleByCatFuncId = 'getRoleByCatFuncId';
export const getSkillByRoleId = 'getSkillByRoleId';
export const findRequestsByEmail = 'findRequestsByEmail';
export const getCareerConversationByRequestId = 'getCareerConversationByRequestId'
export const createTransitionRequest = 'createTransitionRequestTxn';
export const updateStatusAndFeedback = 'updateStatusAndFeedback';
export const upsertVisitorRecord = 'upsertVisitorRecord';
export const createNewNote = 'createNewNote';
export const getUserDiaries = 'getUserDiaries';
export const findNoteByDiaryId = 'findNoteByDiaryId'
export const upsertNotesInDiary = 'upsertNotesInDiary'
