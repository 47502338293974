import { Box, FormControl, TextareaAutosize } from "@mui/material";
import CustomDropdown from "../custom-dropdown/custom-dropdown";
import classes from "./skill-form.module.scss";

function SkillForm({
  isDisabled,
  isFeedbackEnabled,
  label,
  required,
  inputChangeHandler,
  values
}: any) {
  return (
    <div className={classes.SkillForm}>
      <p className={classes.Label}>
        {label} {required && <span className="star">&#42;</span>}
      </p>
      <Box>
        <CustomDropdown
          options={[
            { id: 1, value: "I have the required experience/skill" },
            { id: 2, value: "I partially have the required experience/skill" },
            { id: 3, value: "I do not have the required experience/skill" },
          ]}
          valueKey="value"
          displayKey="value"
          initialValue=""
          value={values?.selectedResponse}
          changeHandler={(event: any) => inputChangeHandler(event.target.value, "selectedResponse")}
          disabled={isDisabled}
          required={true}
          placeholder={"Select Skill"}
        />
        <FormControl fullWidth>
          <TextareaAutosize
            value={values?.responseDescription}
            placeholder={"Share examples of how you have gained this experience"}
            className={classes.textarea}
            minRows={5}
            onChange={(e) => inputChangeHandler(e.target.value, "responseDescription")}
            disabled={isDisabled} />
        </FormControl>

        {isDisabled && <FormControl fullWidth>
          <TextareaAutosize
            value={values?.feedback}
            placeholder={!isFeedbackEnabled ?`Awaiting manager comments` : `Please share your thoughts on the employee's readiness`}
            minRows={5}
            className={classes.textarea}
            onChange={(e) => inputChangeHandler(e.target.value, "feedback")}
            disabled={!isFeedbackEnabled} />
        </FormControl>}
      </Box>
    </div>
  );
}

export default SkillForm;
