export const FORM_SCHEMA = {
    controls: [
        {
            id: "businessUnitId",
            name: "businessUnit",
            label: "Select Business Unit",
            initialValue: null,
            required: true,
            type: "SINGLE_SELECT",
            disabled: false,
            options: [],
            displayKey: "name",
            valueKey: "id",
        },
        {
            id: "categoryId",
            name: "selectCategory",
            label: "Select Category",
            required: true,
            initialValue: null,
            type: "SINGLE_SELECT",
            disabled: true,
            options: [],
            displayKey: "name",
            valueKey: "id",
        },
        {
            id: "functionId",
            name: "selectFunction",
            label: "Select Function",
            required: true,
            initialValue: null,
            type: "SINGLE_SELECT",
            disabled: true,
            options: [],
            displayKey: "name",
            valueKey: "id",
        },
        {
            id: "roleId",
            name: "selectRole",
            label: "Select Role",
            required: true,
            initialValue: null,
            type: "SINGLE_SELECT",
            disabled: true,
            options: [],
            displayKey: "name",
            valueKey: "id",
        },
    ],
};