import CustomTable from "../../../components/custom-table/custom-table.component";
import classes from "./diaries.module.scss";
import { useCallback, useEffect, useState } from "react";
import {getDateYYYYMMDD, getUserProfile} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import {listUserDiaries} from "../../../services/diary.service";

const STATIC_DATA = {
  columns: [
    {
      id: "srNo",
      name: "S. No",
      style: {
        width: "2%"
      },
    },
    {
      id: "topic",
      name: "Topic",
      style: {
        width: "15%",
      },
    },
/*    {
      id: "mentorEmail",
      name: "Mentor Email",
      style: {
        width: "10%"
      },
    },*/
    {
      id: "createdAt",
      name: "Application Date",
      displayName: "Application Date",
      style: {
        width: "10%"
      }
    },
    {
      id: "dateOfConnect",
      name: "Last Date Of Connect",
      displayName: "Application Date",
      style: {
        width: "10%"
      }
    },
    {
      id: "mentoringDiaryId",
      name: "View Document",
      displayName: "View Document",
      style: {
        width: "10%"
      },
      styleClasses: ["hover-primary", "hand"]
    },
  ],
  data: []
};

const Diaries = (props: any) => {
  const [dataSet, setDataSet] = useState<[]>([]);
  const userProfile: any = getUserProfile();
  const urlParams = useParams();
  const navigate = useNavigate();

  const gridActionHandler = async (event: any, mentoringDiaryId: string) => {
    if(event.target.dataset.column === "mentoringDiaryId") {
      navigate(`/mentoring-diary/${mentoringDiaryId}`);
    }
  };

  const fetchGridData = useCallback(async () => {
    let gridData = await listUserDiaries(userProfile.emailId);
    if (gridData && gridData.data.length > 0) {
      const rows: any = []
      gridData.data.forEach((item: any, idx: number) => {
        const newRow: any = { id: item.id }
        STATIC_DATA.columns.forEach((column: any) => {
          if (column.id === "srNo") {
            newRow[column.id] = idx + 1
          } else if (column.id === "createdAt") {
            newRow[column.id] = getDateYYYYMMDD(item[column.id]*1000)
          } else if (column.id === "dateOfConnect") {
            newRow[column.id] = getDateYYYYMMDD(item[column.id])
          } else {
            newRow[column.id] = item[column.id]
          }
        })
        rows.push(newRow)
      })
      setDataSet(rows)
    }
  }, [])

  useEffect(() => {
    fetchGridData();
  }, []);

  return (
    <div className={classes.Requests}>
      <p className={classes.Title}>Status</p>
      <CustomTable
        columns={STATIC_DATA.columns}
        data={dataSet}
        actionHandler={gridActionHandler}
      />
    </div>
  );
};

export default Diaries;
