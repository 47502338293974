import { Box, Button } from "@mui/material";
import { useState, Fragment } from "react";
import CareerAspirations from "./career-aspirations-form/career-aspirations-form";
import CareerHistoryForm from "./career-history-form/career-history-form";
import DevelopmentPlanForm from "./development-plan-form/development-plan-form";
import classes from "./form-view.module.scss";
import FormHeader from "../form-header/form-header.component";
import udaanLogo from "../../assets/icons/udaan-logo.svg"
import { setCurrentFormData } from '../../store/slices/career.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

const FormView = () => {
    const dispatch = useDispatch();
    const formData = useSelector((state: any) => state.career.currentFormData);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>({});

    const urlParams = useParams();
    const userType = urlParams.userType;
    const actionType = urlParams.action;

    const totalSteps = () => {
        return formStepsAndComponents.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ?
                formStepsAndComponents.map((item) => item.label).findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        dispatch(setCurrentFormData({ formName: 'section' + (activeStep + 1), formData: formData['section' + (activeStep + 1)] }));
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        dispatch(setCurrentFormData({ formName: 'section' + (activeStep + 1), formData: formData['section' + (activeStep + 1)] }));
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const isFormInValid = () => {
        let sectionData = formData["section" + (activeStep + 1)];
        switch ("section" + (activeStep + 1)) {
            case "section1":
                if (!sectionData.questionAnswers) {
                    return true;
                }
                return sectionData?.questionAnswers?.filter((item: any) => item.answer === "").length > 0;
                
            case "section2":
                if (!sectionData.questionAnswers
                    || !sectionData.experience
                    || !sectionData.transitionData.businessUnitId
                    || !sectionData.transitionData.categoryId
                    || !sectionData.transitionData.functionId
                    || !sectionData.transitionData.roleId) {
                    return true;
                }
                if(userType === 'manager' && !sectionData.managerFeedback.feedback && !sectionData.experience.length) {
                    return true;
                }
                let questionAnswerCheck = sectionData?.questionAnswers?.filter((item: any) => item.answer === "").length > 0;
                let skillsCheck = false;
                if (sectionData?.experience.length !== 0) {
                    skillsCheck = sectionData?.experience?.filter((item: any) => item.selectedResponse === "" || item.responseDescription === "").length > 0;
                }

                let expFeedbackCheck = false;
                if (sectionData?.experience.length !== 0 && userType === 'manager') {
                    expFeedbackCheck = sectionData?.experience?.filter((item: any) => item.feedback === "").length > 0;
                }

                return !(!questionAnswerCheck && !skillsCheck && !expFeedbackCheck);
            default:
                break;
        }
    }

    const formStepsAndComponents = [
        {
            label: "Section I: Past/Current Career",
            subheading: "In this section, you will reflect on your career so far. \n" +
                "This will help your manager and stakeholders better understand your career journey.\n",
            component: <CareerHistoryForm
                isDisabled={actionType === 'view'}
                parentKey={'section1'}
                handleNext={handleNext} />
        },
        {
            label: "Section II: Career Aspirations",
            subheading: "In this section, you will share details about your aspired next role and your self-evaluation " +
                "of the experiences/skills required for that role. " +
                "Upon submission, your manager will share their views & feedback on the same.",
            component: <CareerAspirations
                isDisabled={actionType === 'view'}
                isFeedbackEnabled={userType === 'manager'}
                isUserView={userType === 'user'}
                parentKey={'section2'}
                handleNext={handleNext} />
        },
        {
            label: "Section III: My Action Plan",
            subheading: "In this section, you will create your action plan to get ready for your aspired next role. " +
                "Upon submission, your manager will share their views & feedback on the same.",
            component: <DevelopmentPlanForm
                isDisabled={actionType === 'view'}
                submitEnabled={(actionType === 'view' && userType === 'manager') ? true : actionType === 'view' ? false : true}
                isManagerFeedback={actionType === 'view' && userType === 'manager'}
                parentKey={'section3'}
                handleNext={handleNext} />
        }
    ]

    return (
        <section className={classes.AppForm}>
            <FormHeader icon={udaanLogo}
                label={formStepsAndComponents[activeStep].label}
                subheading={formStepsAndComponents[activeStep].subheading}
                steps={formStepsAndComponents.map((item) => item.label)}
                activeStep={activeStep}
                completed={completed}
                handleStep={handleStep}
            />
            <Box sx={{ width: '100%' }}>
                <div>
                    <Fragment>
                        <Box margin={4}>
                            {formStepsAndComponents[activeStep].component}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button disabled={activeStep === 2 || isFormInValid()} onClick={handleNext} sx={{ mr: 1 }}>
                                Next
                            </Button>
                        </Box>
                    </Fragment>
                </div>
            </Box>
        </section>
    );
}

export default FormView
