import * as React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import classes from './custom-tooltip.module.scss'
import {IconButton} from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}
             componentsProps={{
                 tooltip: {
                     sx: {
                         color: "#FFFFFF",
                         backgroundColor: "#BE3C44",
            }
        }
    }}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
        opacity: "0.9 !important"
    },
});

export default function CustomTooltip({placement, title, children}:any) {
    return (
        <CustomWidthTooltip title={title} placement="top-start">
            <IconButton className = {classes.toolTipIcon}>
                <HelpIcon/>
                {children}
            </IconButton>
        </CustomWidthTooltip>
    );
}