import { Box, Container } from "@mui/material";
import FormView from "../../components/form-view/form-view";

const CareerConversation = () => {
    return (
        <Container>
            <Box marginTop={8} marginBottom={8}>
                <FormView />
            </Box>
        </Container>
    )
}

export default CareerConversation;