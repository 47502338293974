import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/icons/app-logo.svg";
import homeLogo from "../../assets/icons/home-logo.svg";
import classes from "./navbar.module.scss";
import udaanLogo from "../../assets/icons/udaan-logo.svg";
import UserCard from "../user-card/user-card.component";
import userIcon from "../../assets/icons/user.svg";
import { useCallback, useEffect, useState } from "react";
import {deleteCookies, getUserProfile} from "../../utils/utils";
import { useDispatch } from 'react-redux';
import { resetFormData } from "../../store/slices/career.slice";

function Navbar({ loggedIn }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  let profile: any = getUserProfile();
  const [showLogo, setShowLogo] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const dispatch = useDispatch();

  const checkPath = useCallback(
    (url: string) => ["/", "/requests", "/explore-aarohan", "/logout"].indexOf(url) !== -1,
    []
  );

  const profileVisibilityHandler = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    const shouldShow = checkPath(location.pathname);
    setShowLogo(shouldShow);
    if (!location.pathname.includes('career-conversation')) {
      dispatch(resetFormData());
    }
  }, [location, checkPath]);

  const logoutHandler = () => {
    deleteCookies();
    localStorage.clear();
    const auth2 = window.gapi?.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut()
    }
    navigate("/logout");
    window.location.reload()
  };

  return (
    <nav
      className={`${classes.Navbar} ${loggedIn ? classes.Navbar_LoggedIn : ""}`}
    >
      <ul className={classes.NavbarLogo}>
        <li className="">
          <Link to={"/"}>
            <img src={logo} alt={"Aarohan logo "}/>
          </Link>
        </li>
        {showLogo && (
          <li>
            <img
              src={udaanLogo}
              className={`${classes.UdaanLogo} main-logo`}
              alt={"Udaan logo"}
            />
          </li>
        )}
      </ul>
      {loggedIn && (
        <ul className={classes.NavbarActions}>
          <li style={{ marginRight: "0.75rem" }}><Link to={"/"}>
            <img src={homeLogo} alt={"Home logo"}/>
          </Link></li>
          <li onClick={profileVisibilityHandler} className={classes.Profile}>
            <img src={userIcon} className={`${classes.UserIcon} hand`}  alt={"Hand icon"}/>
            {showProfile && (
              <UserCard
                name={profile?.name}
                emailId={profile?.emailId}
                imageSrc={profile?.imageUrl}
                clickHandler={logoutHandler}
                backdropClickHandler={profileVisibilityHandler}
              />
            )}
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;