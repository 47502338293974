import classes from "./explore.module.scss";
import ExploreCategory from "../../../components/explore-category/explore-category.component";
import careerConversation from "../../../assets/icons/career-conversation-icon.svg"
import exploreAarohan from "../../../assets/icons/explore-aarohan-icon.svg"
import managerInput from "../../../assets/icons/manager-input-icon.svg"
import checkStatus from "../../../assets/icons/check-status-icon.png"
import { Link } from "react-router-dom";
import {getUserProfile} from "../../../utils/utils";
import {updateVisitorRecord} from "../../../services/user.service";
import {useEffect} from "react";

const options = [
  {
    id: 1,
    name: "Explore Aarohan",
    icon: exploreAarohan,
    navigateTo: "/explore-aarohan"
  },
  {
    id: 2,
    name: "My Career Conversation",
    icon: careerConversation,
    navigateTo: "/career-conversation"
  },
  {
    id: 3,
    name: "Manager Dashboard",
    icon: managerInput,
    navigateTo: "/requests/manager"
  },
  {
    id: 4,
    name: "My Career Conversation History",
    icon: checkStatus,
    navigateTo: "/requests/user"
  }
];
const Explore = () => {

  useEffect(() => {
    const userProfile: any = getUserProfile();
    const userEmail: string = userProfile.emailId
    const userName: string =  userProfile.name
    if (userEmail && userName) {
      updateVisitorRecord({emailId: userEmail, name: userName})
    }
  }, []);

  return (
    <div className={classes.Explore}>
      {options?.map(({ id, name, icon, navigateTo }) => (
        <Link key={id} to={navigateTo}>
          <ExploreCategory id={id} name={name} icon={icon}/>
        </Link>
      ))}
    </div>
  );
};

export default Explore;
