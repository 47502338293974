import { axios } from "../config/axios";
import {
    getAllBusinessUnit,
    getCategoriesByBusinessUnitId,
    getCatFuncId,
    getFunctionsByCatId,
    getRoleByCatFuncId,
    getSkillByRoleId
} from '../config/endpoint';

export type BusinessUnitDropdown = {
    id: number,
    name: string
}

export type CategoriesDropdown = {
    id: number,
    name: string,
    businessUnitId: number
}

export type FunctionDropdown = {
    id: number,
    name: string,
}

export type RoleDropdown = {
    id: number,
    name: string,
    categoryFunctionId: number
}

export type Skills = {
    id: number,
    name: string,
    experience: string,
    roleId: number
}

export const getAllBusinessUnitService = async () => {
    return axios.get(`${getAllBusinessUnit}`);
}

export const getCategoriesByBusinessUnitIdService = async (businessUnitId: number) => {
    return axios.get(`${getCategoriesByBusinessUnitId}?buId=${businessUnitId}`);
}

export const getFunctionsByCatIdService = async (categoryId: number) => {
    return axios.get(`${getFunctionsByCatId}?catId=${categoryId}`);
}

export const getRoleByCatFuncIdService = async (categoryFunctionId: number) => {
    return axios.get(`${getRoleByCatFuncId}?catFuncId=${categoryFunctionId}`);
}

export const getSkillByRoleIdService = async (roleId: number) => {
    return axios.get(`${getSkillByRoleId}?roleId=${roleId}`);
}

export const getCatFuncIdService = async (catId: number, funcId: number) => {
    return axios.get(`${getCatFuncId}?catId=${catId}&funcId=${funcId}`);
}