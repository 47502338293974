import { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { getQuestionsBySectionIdService, Question } from '../../../services/career-history.service';

import { FormControl, TextareaAutosize, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFormData } from '../../../store/slices/career.slice';

import classes from "../form-view.module.scss";
import CustomTooltip from "../../custom-tooltip/custom-tooltip.component";
import { getPlaceholder } from "../../../utils/utils"

type Props = {
    isDisabled: boolean,
    parentKey: string,
    handleNext?: any
}

const CareerHistoryForm = (props: Props) => {
    const { parentKey, handleNext, isDisabled } = props;
    const formData = useSelector((state: any) => state.career.currentFormData);
    const dispatch = useDispatch();
    const [questions, setQuestions] = useState<Question[]>([]);

    const fetchQuestionsData = useCallback(async () => {
        let questions = await getQuestionsBySectionIdService(1);
        setQuestions(questions.data.map((item: Question) => item));

        let sectionData = JSON.parse(JSON.stringify(formData));
        if(sectionData[parentKey].questionAnswers?.length !== questions.data.length) {
            sectionData[parentKey].questionAnswers = questions.data.map((item: Question) => {
                return { questionId: item.id, answer: '' }
            })
            dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
        }

    }, [])

    useEffect(() => {
        fetchQuestionsData();
    }, []);

    const handleChange = (answer: string, questionId: number, index: number) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        if (!sectionData[parentKey].questionAnswers) {
            sectionData[parentKey].questionAnswers = [];
        }
        sectionData[parentKey].questionAnswers[index] = ({ questionId, answer });
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }

    const sanitizeDomElement = useCallback( (element: string) => {
        return <div className='padding-20' dangerouslySetInnerHTML={{ __html: element }} />;
    },[])

    return (
        <Formik
            initialValues={formData[parentKey]}
            onSubmit={() => { handleNext() }}
        >
            <Form>
                {questions.map((item: Question, index) => <div key={index}>
                    <FormControl required fullWidth>
                        <Typography component={'span'} className={classes.Description}>
                            {index + 1}. {item.question}
                            <span className={classes.required}> *</span>
                            <CustomTooltip title={sanitizeDomElement(item.description)}/>
                        </Typography>
                        <TextareaAutosize
                            id={"answer"}
                            required
                            placeholder={getPlaceholder(item.id)}
                            value={formData[parentKey]?.questionAnswers?.[index]?.answer}
                            minRows={5} className={classes.textarea}
                            onChange={(e) => handleChange(e.target.value, item.id, index)}
                            disabled={isDisabled} />
                    </FormControl>

                </div>)}
            </Form>
        </Formik>
    )
}

export default CareerHistoryForm;