import { axios } from "../config/axios";
import { createTransitionRequest, updateStatusAndFeedback } from '../config/endpoint';

export interface QuestionAnswer {
    questionId: number;
    answer: string;
}

export interface Section1 {
    questionAnswers: QuestionAnswer[];
}

export interface TransitionData {
    categoryId: number;
    functionId: number;
    roleId: number;
    businessUnitId: number;
    userName: string;
    userEmail: string;
    managerEmail: string;
}

export interface Experience {
    skillId: number;
    selectedResponse: string;
    responseDescription: string;
    feedback: string;
}

export interface Section2 {
    transitionData: TransitionData;
    experience: Experience[];
}

export interface Strength {
    details: string;
    role: string;
    completedBy: number;
    stepsToAchieve70: string;
    stepsToAchieve20: string;
    stepsToAchieve10: string;
    status: string;
}

export interface ManagerIjpFeedback {
    feedback: string,
    managerEmail: string
}

export interface Section3 {
    numberOfStrengths: number;
    strengths: Strength[];
}

export interface FormData {
    section1: Section1;
    section2: Section2;
    section3: Section3;
}

export const createTransitionRequestService = async (formData: FormData) => {
    return axios.post(`${createTransitionRequest}`, { formData });
}

export const updateStatusAndFeedbackService = async (formData: FormData, reqId: string) => {
    return axios.put(`${updateStatusAndFeedback}?reqId=${reqId}`, { formData });
}

