import { BrowserRouter } from "react-router-dom";
import AppRouter from "./routes/app-router/app-router.component";
import store, { persistor } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <div className="App">
            <AppRouter></AppRouter>
          </div>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
