import CustomTable from "../../../components/custom-table/custom-table.component";
import classes from "./requests.module.scss";
import { useCallback, useEffect, useState } from "react";
import { findRequestsByEmailService, getCareerConversationByRequestIdService } from "../../../services/requests.service";
import {getDateYYYYMMDD, getUserProfile} from "../../../utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setFormData } from "../../../store/slices/career.slice";

const STATIC_DATA = {
  columns: [
    {
      id: "srNo",
      name: "S. No",
      displayName: "S. No",
      style: {
        width: "2%"
      },
    },
    {
      id: "userName",
      name: "User Name",
      displayName: "User Name",
      style: {
        width: "10%",
      },
    },
    {
      id: "userEmail",
      name: "User Email",
      displayName: "User Email",
      style: {
        width: "10%"
      },
    },
    {
      id: "managerEmail",
      name: "Manager Email",
      displayName: "Manager Email",
      style: {
        width: "10%"
      },
    },
    {
      id: "status",
      name: "Status",
      displayName: "Status",
      style: {
        width: "7%"
      }
    },
    {
      id: "createdAt",
      name: "Application Date",
      displayName: "Application Date",
      style: {
        width: "10%"
      }
    },
    {
      id: "requestId",
      name: "View Document",
      displayName: "View Document",
      style: {
        width: "10%"
      },
      styleClasses: ["hover-primary", "hand"]
    },
  ],
  data: []
};

const Requests = (props: any) => {
  const [dataSet, setDataSet] = useState<[]>([]);
  const userProfile: any = getUserProfile();
  const urlParams = useParams();
  const userType = urlParams.userType?.toUpperCase() || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gridActionHandler = async (event: any, requestId: string) => {
    if(event.target.dataset.column === "requestId") {
      let formData = await getCareerConversationByRequestIdService(requestId);
      dispatch(setFormData(formData));
      navigate(`/career-conversation/${userType.toLowerCase()}/view`);
    }
  };

  const fetchGridData = useCallback(async () => {
    let gridData = await findRequestsByEmailService(userProfile.emailId, userType);
    if (gridData && gridData.data.length > 0) {
      const rows: any = []
      gridData.data.forEach((item: any, idx: number) => {
        const newRow: any = { id: item.id }
        STATIC_DATA.columns.forEach((column: any) => {
          if (column.id === "srNo") {
            newRow[column.id] = idx + 1
          } else if (column.id === "createdAt") {
            newRow[column.id] = getDateYYYYMMDD(item[column.id]*1000)
          } else {
            newRow[column.id] = item[column.id]
          }
        })
        rows.push(newRow)
      })
      setDataSet(rows)
    }
  }, [])

  useEffect(() => {
    fetchGridData();
  }, []);

  return (
    <div className={classes.Requests}>
      <p className={classes.Title}>Status</p>
      <CustomTable
        columns={STATIC_DATA.columns}
        data={dataSet}
        actionHandler={gridActionHandler}
      />
    </div>
  );
};

export default Requests;
