import classes from "./banner-image.module.scss";

const BannerImage = ({ imageUrl }: any) => {
  return (
    <div
      className={classes.BannerImaage}
      style={{ backgroundImage: `url(${imageUrl})` }}
    ></div>
  );
};

export default BannerImage;
