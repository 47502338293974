import { axios } from "../config/axios";
import {
    getUserDiaries, createNewNote,
    findNoteByDiaryId, upsertNotesInDiary
} from '../config/endpoint';

export interface MentoringDiary{
    topic: "",
    dateOfConnect: "",
    notes: "",
    userEmail: "",
    mentorEmail: ""
}

export interface MentoringDiaryUpdate{
    topic: "",
    dateOfConnect: "",
    notes: "",
    userEmail: "",
    mentorEmail: "",
    createdAt: "",
    mentoringDiaryId: ""
}

export const listUserDiaries = async (email: string) => {
    return axios.get(`${getUserDiaries}?userEmail=${email}`);
}

export const createNewMentoringDiary = async (formData: MentoringDiary) => {
    return axios.post(`${createNewNote}`, formData);
}

export const getNoteByDiaryId = async (mentoringDiaryId: string) => {
    return axios.get(`${findNoteByDiaryId}/${mentoringDiaryId}`);
}

export const updateNotesInDiary = async (formData: MentoringDiaryUpdate) => {
    return axios.put(`${upsertNotesInDiary}`, formData);
}