import {useCallback, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {getQuestionsBySectionIdService, Question} from '../../../services/career-history.service';
import {FormControl, TextareaAutosize, Typography} from '@mui/material';
import {
    BusinessUnitDropdown,
    CategoriesDropdown,
    FunctionDropdown,
    getAllBusinessUnitService,
    getCategoriesByBusinessUnitIdService,
    getCatFuncIdService,
    getFunctionsByCatIdService,
    getRoleByCatFuncIdService,
    getSkillByRoleIdService,
    RoleDropdown,
    Skills
} from '../../../services/career-aspirations.service';
import ApplicationFormInputs from '../../application-form-inputs/application-form-inputs.component';
import {FORM_SCHEMA} from './career-aspiration-form.schema';
import ExperienceViewer from '../../experience-viewer/experience-viewer.component';
import {setCurrentFormData} from '../../../store/slices/career.slice';
import {useDispatch, useSelector} from 'react-redux';
import {getPlaceholder, getUserProfile} from '../../../utils/utils';

import classes from "../form-view.module.scss";
import CustomTooltip from "../../custom-tooltip/custom-tooltip.component";

type Props = {
    isDisabled: boolean,
    isFeedbackEnabled: boolean,
    isUserView: boolean,
    parentKey: string,
    handleNext?: any
}

const CareerAspirations = (props: Props) => {
    const { parentKey, handleNext, isDisabled, isFeedbackEnabled, isUserView } = props;
    const formData = useSelector((state: any) => state.career.currentFormData);
    const dispatch = useDispatch();
    const [questions, setQuestions] = useState<Question[]>([]);
    const [skills, setSkills] = useState<Skills[]>([]);
    const [schema, setSchema]: any = useState({
        ...FORM_SCHEMA,
        controls: [...FORM_SCHEMA.controls],
    });
    const [showExperience, setShowExperience] = useState(false);
    const userProfile: any = getUserProfile();

    const fetchIntialQuestionsData = useCallback(async () => {
        let questions = await getQuestionsBySectionIdService(2);
        setQuestions(questions.data as Question[]);
    }, []);

    const fetchBusinessUnit = useCallback(async () => {
        let businessUnits = await getAllBusinessUnitService();
        updateSchemaState("businessUnitId", { disabled: isDisabled, options: businessUnits.data as BusinessUnitDropdown[] });
    }, []);

    const fetchCategoriesByBusinessUnitId = useCallback(async () => {
        let categories = await getCategoriesByBusinessUnitIdService(formData[parentKey].transitionData.businessUnitId);
        updateSchemaState("categoryId", { disabled: isDisabled, options: categories.data as CategoriesDropdown[] });
    }, [formData[parentKey].transitionData.businessUnitId]);

    const fetchFunctionsByCategoryId = useCallback(async () => {
        let functions = await getFunctionsByCatIdService(formData[parentKey].transitionData.categoryId);
        updateSchemaState("functionId", { disabled: isDisabled, options: functions.data as FunctionDropdown[] });
    }, [formData[parentKey].transitionData.categoryId]);

    const fetchRoleByFunctionCategoryId = useCallback(async () => {
        let catFuncId = await getCatFuncIdService(formData[parentKey].transitionData.categoryId, formData[parentKey].transitionData.functionId);
        let roles = await getRoleByCatFuncIdService(catFuncId?.data?.[0]?.id);
        updateSchemaState("roleId", { disabled: isDisabled, options: roles.data as RoleDropdown[] });
    }, [formData[parentKey].transitionData.functionId]);

    const fetchSkillsByRoleId = useCallback(async () => {
        let skills = await getSkillByRoleIdService(formData[parentKey].transitionData.roleId);
        let sectionData = JSON.parse(JSON.stringify(formData));
        if (skills.length === 0) {
            setSkills([] as Skills[]);
            sectionData[parentKey].experience = [];
            setShowExperience(false);
        } else {
            setSkills(skills.data as Skills[]);
            let tempSkillsData = skills.data.map((skill: Skills) => {
                return {
                    skillId: skill.id,
                    selectedResponse: "",
                    responseDescription: "",
                    feedback: ""
                }
            })
            if (!sectionData[parentKey].experience?.length) {
                sectionData[parentKey].experience = tempSkillsData;
            }
            setShowExperience(true);
        }
        sectionData[parentKey].transitionData.userName = userProfile?.name;
        sectionData[parentKey].transitionData.userEmail = userProfile?.emailId;
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }, [formData[parentKey].transitionData.roleId]);

    useEffect(() => {
        fetchIntialQuestionsData().then(r =>{});
        fetchBusinessUnit().then(r =>{});
    }, []);

    useEffect(() => {
        if (formData[parentKey].transitionData.businessUnitId) {
            fetchCategoriesByBusinessUnitId().then(r =>{});
        }
    }, [formData[parentKey].transitionData.businessUnitId]);

    useEffect(() => {
        if (formData[parentKey].transitionData.categoryId) {
            fetchFunctionsByCategoryId().then(r =>{});
        }
    }, [formData[parentKey].transitionData.categoryId]);

    useEffect(() => {
        if (formData[parentKey].transitionData.functionId) {
            fetchRoleByFunctionCategoryId().then(r =>{});
        }
    }, [formData[parentKey].transitionData.functionId]);

    useEffect(() => {
        if (formData[parentKey].transitionData.roleId) {
            fetchSkillsByRoleId().then(r =>{});
        }
    }, [formData[parentKey].transitionData.roleId]);

    const handleChange = (answer: string, questionId: number, index: number) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        if (!sectionData[parentKey].questionAnswers) {
            sectionData[parentKey].questionAnswers = [];
        }
        sectionData[parentKey].questionAnswers[index] = ({ questionId, answer });
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }

    const resetSchema = async (controlIdx: number, newState: any) => {
        if (controlIdx !== -1 && controlIdx < schema.controls?.length) {
            schema.controls[controlIdx] = {
                ...schema.controls[controlIdx],
                ...newState,
            };
            const tempSchema = {
                ...schema,
                controls: [...schema.controls],
            };
            setSchema(tempSchema);
        }
    };

    const inputChangeHandler = async (id: any, event: any, index: number) => {
        let sectionData = JSON.parse(JSON.stringify(formData));

        // resetting subsequent dropdown
        for (let i = index + 1; i < schema.controls.length; i++) {
            const resetState = FORM_SCHEMA.controls[i];
            sectionData[parentKey].transitionData[resetState.id] = "";
            await resetSchema(i, {...resetState});
        }

        if (index === schema.controls.length - 1) {
            setShowExperience(true);
        } else {
            setShowExperience(false);
        }
        sectionData[parentKey].transitionData[id] = event.target.value;
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    };

    const updateSchemaState = (id: string, newState: any) => {
        const controlIdx = schema.controls.findIndex((item: any) => item.id === id);
        if (controlIdx !== -1 && controlIdx < schema.controls.length) {
            schema.controls[controlIdx] = {
                ...schema.controls[controlIdx],
                ...newState,
            };
            const tempSchema = {
                ...schema,
                controls: [...schema.controls],
            };
            setSchema(tempSchema);
        }
    };

    const experienceChangeHandler = async (index: number, value: string, key: string) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        sectionData[parentKey].experience[index][key] = value;
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }

    const handleFeedbackHandler = (e: any) => {
        let sectionData = JSON.parse(JSON.stringify(formData));
        if(!sectionData[parentKey].managerFeedback) {
            sectionData[parentKey].managerFeedback = {}
        }
        sectionData[parentKey].managerFeedback.feedback = e.target.value;
        sectionData[parentKey].managerFeedback.managerEmail = '';
        dispatch(setCurrentFormData({ formName: parentKey, formData: sectionData[parentKey] }));
    }
    

    return (
        <Formik
            initialValues={formData[parentKey]}
            onSubmit={() => { handleNext() }}
        >
            <Form>
                {questions.map((item: Question, index) => <div key={index}>
                    <Typography component={'span'} className={classes.Description}>
                        {index + 1}. {item.question}
                        <span className={classes.required}> *</span>
                        <CustomTooltip title={item.description}/>
                    </Typography>
                    <TextareaAutosize
                        disabled={isDisabled}
                        placeholder={getPlaceholder(item.id)}
                        value={formData[parentKey].questionAnswers?.[index]?.answer}
                        minRows={5} className={classes.textarea}
                        onChange={(e) =>
                            handleChange(e.target.value, item.id, index)} required />
                </div>)}
                <Typography component={'span'} className={classes.Description}>
                    2. What is your aspired next role?
                    <CustomTooltip title={<ul>
                            <li>Think of your ultimate destination role.</li>
                            <li>Use the experience/skill maps available in the “Explore Aarohan”
                                section to identify the experience/skills required to reach there. </li>
                            <li>Identify your next role that will be a stepping stone to reaching your
                                destination role - <u>this would be your aspired next role</u></li>
                        </ul>}>
                    </CustomTooltip>
                    <p className={classes.infoLink}>
                        <a href="https://udaandotcom-my.sharepoint.com/personal/sonali_r_udaan_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsonali%5Fr%5Fudaan%5Fcom%2FDocuments%2FAarohan%2FExperience%20Maps&ga=1"
                           target="_blank" rel="noreferrer">
                            <span>click here to explore roles across udaan & experiences required for the roles</span>
                        </a>
                    </p>
                </Typography>
                <ApplicationFormInputs
                    inputChangeHandler={inputChangeHandler}
                    values={formData[parentKey].transitionData}
                    controls={schema.controls}
                />
                {isDisabled &&
                    formData[parentKey].experience?.length === 0 &&
                    <FormControl sx={{ mb: 6 }} fullWidth={true}>
                    <Typography component={'span'} className={classes.Description}> {'Manager comments'}  <span className={classes.required}> *</span></Typography>
                    <TextareaAutosize
                        disabled={!isFeedbackEnabled}
                        placeholder={isUserView ?'Awaiting manager comments' : 'Share detailed comments '}
                        value={formData[parentKey]?.managerFeedback?.feedback}
                        minRows={5} className={classes.textarea}
                        onChange={handleFeedbackHandler}
                        required />
                </FormControl>}

                {!isFeedbackEnabled &&
                    formData[parentKey].experience?.length === 0 &&
                    formData[parentKey].transitionData.roleId &&
                    <Typography color={"#D34A53"} component={'span'} className={classes.Description}>Please discuss the experiences required for this role during your career conversation with your manager</Typography>
                }
                {showExperience &&
                    <ExperienceViewer
                        isDisabled={isDisabled}
                        isFeedbackEnabled={isFeedbackEnabled}
                        className={classes.ExperienceViewer}
                        skills={skills}
                        inputChangeHandler={experienceChangeHandler}
                        experienceForm={formData[parentKey].experience}
                    />
                }
            </Form>
        </Formik>
    )
}

export default CareerAspirations;