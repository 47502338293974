import SkillForm from "../skill-form/skill-form.component";
import classes from "./skill-card.module.scss";

function SkillCard({
  isDisabled,
  isFeedbackEnabled,
  skill: { id: skillId, name: skillName, experience: skillDescription },
  index,
  inputChangeHandler,
  experienceForm,
}: any) {

  return (
    <div className={classes.SkillCard}>
      <p className={classes.Label}>
        {index}. {skillName}
      </p>
      <span className={classes.Description}>{skillDescription}</span>
      <SkillForm
        isDisabled={isDisabled}
        isFeedbackEnabled={isFeedbackEnabled}
        label="Experience/Skill Self Review"
        required="true"
        inputChangeHandler={inputChangeHandler}
        values={experienceForm}
      />
    </div>
  );
}

export default SkillCard;
